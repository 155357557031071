import { HrefType } from "@/components-modern/atoms/link/Link";
import { navbarAnalyticsInfo } from "@/utils/analyticsConstants";
import { KEY_TERMS_PAGES } from "@hours/constants/build/main/lib/Library";
import { ITrackEvent } from "@hours/utilities";
import { Subject } from "@/store/api/graphql/generated/types";

export interface INavLink {
  label: string;
  key: string;
  href?: string | HrefType;
  onClick?: () => void;
  style?: React.CSSProperties;
  isNew?: boolean;
  showActive?: boolean;
  isButton?: boolean;
  hideForPaidUsers?: boolean;
}

interface IgetLinksProps {
  trackEvent: (event: ITrackEvent) => void;
  hasCramMode: boolean;
  isDev?: boolean;
  isLoggedIn: boolean;
  activeSubject?: Subject;
}

// eslint-disable-next-line max-lines-per-function
export const getLinks = ({
  trackEvent,
  activeSubject,
}: IgetLinksProps): { allLinks: INavLink[]; avatarLinks: INavLink[] } => {
  const trackNavEvent = (label, other = {}) => {
    trackEvent({ ...navbarAnalyticsInfo.NAV_ITEM_CLICK, label, other });
  };

  const subjectSlug = activeSubject?.slug;

  const { hasPracticeQuestions, hasKeyTerms, hasCalculators } = activeSubject || {};

  const subjectPath = subjectSlug ? `/${subjectSlug}` : "";

  const links: INavLink[] = [
    hasKeyTerms && {
      label: "Glossary",
      key: "navLinkKeyTerms",
      href: KEY_TERMS_PAGES.KEY_TERMS_PATH + subjectPath,
      onClick: () => trackNavEvent("Key Terms"),
    },
    hasPracticeQuestions && {
      label: "Practice Quizzes",
      key: "navLinkPractice",
      href: `/practice${subjectPath}`,
      onClick: () => trackNavEvent("Practice Mode"),
    },

    hasCalculators && {
      label: "Score Calculator",
      key: "navLinkScoreCalculator",
      href: `/ap-score-calculator${subjectPath}`,
      onClick: () => trackNavEvent("AP Score Calculator"),
    },
  ];

  const loggedInLinks = [
    {
      label: "Log Out",
      key: "navLinkLogout",
      href: "/logout",
    },
  ];

  const avatarLinksUnfiltered = [
    {
      label: "My Stuff",
      key: "navLinkMyStuff",
      href: "/my-stuff",
      onClick: () => trackNavEvent("Cram HQ", { source: "avatar-menu" }),
    },
    {
      label: "Settings",
      href: "/dashboard/settings",
      key: "navLinkSettings",
      onClick: () => trackNavEvent("Settings", { source: "avatar-menu" }),
    },
    {
      label: "Support",
      key: "navLinkSupport",
      href: "mailto:help@fiveable.me",
      onClick: () => trackNavEvent("Settings", { source: "avatar-menu" }),
    },
    // if seeing avatar, is logged in
    ...loggedInLinks,
  ];

  const allLinks = links.filter(Boolean);
  const avatarLinks = avatarLinksUnfiltered.filter(Boolean);

  return { allLinks, avatarLinks };
};
