/* eslint-disable max-lines-per-function */
import { useSearchParams } from "next/navigation";
import { getRouterSlugString } from "@/utils/Library/helpers";
import { generateTabUrl } from "@/utils/readingPracticeNavUtils";
import { isSubjectWithPracticeQuestions } from "@/hooks/useReadingPracticeNavBar";
import { Content, Subject, Unit } from "@/store/api/graphql/generated/types";
import { useModernSelector } from "@/store/hooks";
import { selectActiveUnit, selectUnits } from "@/store/slices/TableOfContentsSlice";
import { useSortUnits } from "@/utils/useSortUnits";
import { showGSCWidget } from "@/utils/Library/documentScripts/getSiteControl";
import { getPagePath, getNextPathForUnitPage } from "./useBottomNav.util";

export type LinkGroupLink = {
  href: string;
  label: string;
  id: string;
  onClick?: () => void;
};

interface IuseBottomNavReturn {
  linkGroupLinksDesktop?: LinkGroupLink[];
  linkGroupLinksMobile?: LinkGroupLink[];
  nextPrevLinkInfo: {
    next: {
      href: string;
      label: string;
      isEnabled?: boolean;
    };
    prev: {
      href: string;
      label: string;
      isEnabled?: boolean;
    };
  };
}

export interface IuseBottomNavProps {
  pageData: {
    pepQuizStatus?: string;
    pepQuizQuestions?: string[];
    pepQuizCompleted?: boolean;
    pepQuizIsVisible?: boolean;
    hasGoogleReferrer?: boolean;
    hasCramMode?: boolean;
    content?: Content;
    subject?: Subject;
    unit?: Unit;
    [key: string]: unknown;
  };
  pathParams?: Record<string, string | string[]> | { [key: string]: string | string[] };
  pathname: string;
}

const extractBottomNavData = ({ pageData, reduxActiveUnit, sortedUnits }) => {
  let currentSubjectUnits = [];

  // use units from toc slice of redux if possible, with same sorting as side nav
  if (sortedUnits.courseUnits.length > 0 || sortedUnits.addlResources.length > 0) {
    currentSubjectUnits = [...sortedUnits.courseUnits, ...sortedUnits.addlResources];
  }

  // if that didn't work look for content page pageData
  if (!currentSubjectUnits?.length && pageData?.content) {
    // do need to filter for active units on pageData
    currentSubjectUnits = pageData?.content?.subject?.units?.filter((u) => u?.active) || [];
  }

  // if that didn't work try for subject page pageData
  if (!currentSubjectUnits?.length && pageData?.subject) {
    currentSubjectUnits = pageData?.subject?.units?.filter((u) => u?.active);
  }

  let activeUnit = currentSubjectUnits?.find((u) => u.id === reduxActiveUnit?.id);
  let sortedContent = activeUnit?.resources;

  if (!activeUnit) {
    // if we're here, the units/resources were not able to be pulled from the toc slice of redux
    // so order them by keyTopic
    // TODO: both from separate parts of same query, should probs consolidate eventually
    activeUnit = pageData?.content?.unit || reduxActiveUnit || pageData?.unit || {};
    const resourceArr = activeUnit?.resources || [];
    // sort resources by keyTopic - the number after the decimal in X.1
    const resources = [...resourceArr];
    sortedContent = resources.sort((a, b) => {
      // Check if keyTopics exists and is not empty for both elements
      if (a.keyTopics?.length > 0 && b.keyTopics?.length > 0) {
        return Number(a.keyTopics[0]) - Number(b.keyTopics[0]);
      }
      // If keyTopics is missing or empty for either element, leave the order unchanged
      return 0;
    });
  }

  return { currentSubjectUnits, activeUnit, sortedContent };
};

const useBottomNav = ({ pageData, pathParams, pathname }: IuseBottomNavProps): IuseBottomNavReturn => {
  // TODO: might not have these later.
  const reduxUnits = useModernSelector(selectUnits);
  const reduxActiveUnit = useModernSelector(selectActiveUnit);
  const searchParams = useSearchParams();
  // sort the units from redux the same as they will appear in side nav
  // otherwise the order of units is different here causing the next button of the last guide being inconsistent with the side nav
  // this list of units should only contain active units by default
  const sortedUnits = useSortUnits(reduxUnits, true);
  const { currentSubjectUnits, activeUnit, sortedContent } = extractBottomNavData({
    pageData,
    reduxActiveUnit,
    sortedUnits,
  });

  const shouldHideGlossaryLink = pageData?.subject?.keyTermsActive === false;

  const nextLabelDefault = "Next";
  const prevLabelDefault = "Back";

  const nextPrevLinkInfo = {
    next: {
      href: "/",
      label: nextLabelDefault,
      isEnabled: true,
    },
    prev: {
      href: "/",
      label: prevLabelDefault,
      isEnabled: true,
    },
  };

  const {
    unitSlug: routerUnitSlug,
    docId: routerDocIdSlug,
    contentSlug: routerContentSlug,
  } = pathParams ?? {};

  // const { unitSlug: routerUnitSlug, docId: routerDocIdSlug, contentSlug: routerContentSlug } = router.query;

  // we aren't really consistent with how we categorize the subject on different pages
  // this represents content/content-adjacent pages, key terms pages, and practice pages respectively
  const routerSubSlug = pathParams?.communitySlug || pathParams?.subjectSlug || pathParams?.slug?.[0];

  if (!routerSubSlug) {
    nextPrevLinkInfo.next.href = null;
    nextPrevLinkInfo.prev.href = null;
    nextPrevLinkInfo.next.isEnabled = false;
    nextPrevLinkInfo.prev.isEnabled = false;
    return { nextPrevLinkInfo };
  }

  // appeases typescript in relation to slug strings
  const subSlug = getRouterSlugString(routerSubSlug);
  const unitSlug = getRouterSlugString(routerUnitSlug);
  const docId = getRouterSlugString(routerDocIdSlug);
  const contentSlug = getRouterSlugString(routerContentSlug);

  const activeUnitResources = activeUnit?.resources || [];
  const activeUnitGuide = activeUnitResources.findIndex((r) => r.id === docId);
  const prevGuide = activeUnitResources[activeUnitGuide - 1];
  const nextGuide = activeUnitResources[activeUnitGuide + 1];

  nextPrevLinkInfo.next.label = nextGuide?.title || nextLabelDefault;
  nextPrevLinkInfo.prev.label = prevGuide?.title || prevLabelDefault;

  const hasPracticeQuestions = isSubjectWithPracticeQuestions({
    subjectSlug: subSlug,
    unitPageSlug: unitSlug,
    questionType: "practice",
  });
  const isSubjectPage = subSlug && !unitSlug && !docId;
  const isUnitPage = subSlug && unitSlug && !docId;
  const isContentPage = subSlug && unitSlug && docId;
  const isKeyTermPage = pathname.startsWith("/key-terms");
  const isPracticePage = pathname.startsWith("/practice") || pathname.startsWith("/ap-style-practice");
  const isQuestionPage = pathname.startsWith("/questions");
  const isCalculatorPage = pathname.startsWith("/ap-score-calculator");

  const topicId = pageData?.content?.topics?.[0]?.id;

  const practiceUrl =
    generateTabUrl({
      pathname,
      pathParams,
      searchParams,
      contentSlug,
      contentId: docId,
      topicId,
      generatedUrlType: "practice",
    }) || `/practice/${subSlug}`;

  const practiceNavLink = hasPracticeQuestions
    ? {
        id: "practiceMore",
        href: isKeyTermPage || isCalculatorPage ? `/practice/${subSlug}` : practiceUrl,
        label: "Practice Quiz",
      }
    : {
        // 09/23/24 - experiment to show gsc widget when there are no PQ's
        // for a subject, don't pass href to link to render Button - BC
        id: "practiceMore",
        label: "Practice Quiz",
        onClick: () => showGSCWidget(421408),
      };

  const keyTermsNavLink = shouldHideGlossaryLink
    ? {}
    : { id: "keyTermsBottomNavLink", href: `/key-terms/${subSlug}`, label: "Glossary" };

  const guideNavLink = { id: "guideBottomNavLink", href: `/${subSlug}`, label: "Guides" };

  const contentPageLinks = [practiceNavLink, keyTermsNavLink];

  const keyTermPageLinks = [practiceNavLink, guideNavLink];

  const practicePageLinks = [guideNavLink, keyTermsNavLink];

  // on mobile guide button should not link directly to guide page
  // it opens a modal instead
  const allLinksMobile = [practiceNavLink, { ...guideNavLink, href: null }, keyTermsNavLink];

  const allLinks = [practiceNavLink, guideNavLink, keyTermsNavLink];

  let linkGroupLinksDesktop = [];
  // filter out empty keyterms link if present
  const linkGroupLinksMobile = allLinksMobile.filter((link) => !!link.id);

  if (isContentPage || isSubjectPage || isUnitPage) linkGroupLinksDesktop = contentPageLinks;
  if (isKeyTermPage) linkGroupLinksDesktop = keyTermPageLinks;
  if (isPracticePage || isQuestionPage) linkGroupLinksDesktop = practicePageLinks;
  if (isCalculatorPage) linkGroupLinksDesktop = allLinks;

  // filter out empty keyterms link if present
  linkGroupLinksDesktop = linkGroupLinksDesktop.filter((link) => !!link.id);

  // if on a subject page, next link is unit page for unit-1 of that subject
  if (isSubjectPage) {
    nextPrevLinkInfo.next.href = `/${subSlug}/${currentSubjectUnits?.[0]?.slug}`;
    nextPrevLinkInfo.prev.href = null;
    nextPrevLinkInfo.prev.isEnabled = false;
  }

  // if on a unit page, next link is unit overview page
  if (isUnitPage) {
    const nextUrl = getNextPathForUnitPage({ resources: activeUnit?.resources, subSlug, unitSlug });

    nextPrevLinkInfo.next.href = nextUrl;
    nextPrevLinkInfo.prev.href = null;
    nextPrevLinkInfo.prev.isEnabled = false;
  }

  if (isContentPage) {
    const unitId = activeUnit?.id;
    const currentUnitIndex = currentSubjectUnits?.findIndex((unit) => unit.id === unitId);
    const nextUnit = currentSubjectUnits?.[currentUnitIndex + 1];

    const { nextUrl, prevUrl, isLastGuide, isFirstGuide } = getPagePath({
      sortedContent,
      subSlug,
      unitSlug,
      docId,
    });

    nextPrevLinkInfo.next.href = nextUrl;
    nextPrevLinkInfo.prev.href = prevUrl;

    // if is the first guide (overview page) then the prev link is the unit page
    if (isFirstGuide) {
      nextPrevLinkInfo.prev.href = `/${subSlug}/${unitSlug}`;
    }

    if (isLastGuide) {
      // if this is the last guide in the current unit, but subsequent units exist, then next link is the next unit
      if (nextUnit) {
        nextPrevLinkInfo.next.href = `/${subSlug}/${nextUnit?.slug}`;
        // if this is the last guide in the last unit (there is no next unit), then next link is the current subject
      } else {
        nextPrevLinkInfo.next.href = `/${subSlug}`;
      }
    }
  }

  const pageShouldShowNextPrev = !isKeyTermPage && !isPracticePage && !isCalculatorPage && !isQuestionPage;

  nextPrevLinkInfo.next.isEnabled = pageShouldShowNextPrev;
  nextPrevLinkInfo.prev.isEnabled = pageShouldShowNextPrev;

  return {
    linkGroupLinksDesktop,
    linkGroupLinksMobile,
    nextPrevLinkInfo,
  };
};

export default useBottomNav;
